import React from "react"
import styled from "styled-components"
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "gatsby-plugin-intl"

import EveryAction from "../components/everyaction"
import Language from "../components/language"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubscribeForm from "../components/subscribeform"
import Units from "../components/units"
import WhatsApp from "../components/whatsapp"

const Hero = styled.section`
  background: #eae56a;
  color: #000;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;
  padding: 2rem 20px;
  a {
    color: #000000;
  }
  div {
    margin: 0 auto;
  }
  h1 {
    margin: 3rem auto 0 auto;
    font-size: 4.5rem;
    text-align: center;
    letter-spacing: 0.5rem;
    flex: 0 0 100%;
    font-family: "Bebas Neue", sans-serif;
  }
  h2 {
    font-size: .8rem;
    text-transform: uppercase;
    margin-top: .5rem;
    margin-bottom: 3rem;
  }
  div.intro {
    flex: 0 0 100%;
    margin: 2rem auto;
    max-width: 700px;
  }
  div.intro p {
    text-align: left;
    font-size: .95rem;
    line-height: 1.6;
    font-family: Open Sans, sans-serif;
  }
  p.privacy-policy {
    text-align: center;
    font-family: Open Sans, sans-serif;
    margin: 0 auto;
    max-width: 700px;
    line-height: 1.3;
    font-size: .75rem;
  }
  form {
    margin-bottom: 2rem;
  }
  @media (max-width: 680px) {
    h1 {
      font-size: 4rem;
    }
    h2 {
      font-size: .7rem;
    }
  }
`

const IndexPage = ({ intl }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: "title" })} />

    <Hero>
      <Language />
      <h1><FormattedMessage id="title" /></h1>
      <h2><FormattedMessage id="tagline" /></h2>
      <SubscribeForm />
      <WhatsApp />

      <div className="intro">
        <FormattedHTMLMessage id="introtext" />
      </div>

      <p className="privacy-policy"><FormattedHTMLMessage id="privacypolicy" /></p>
    </Hero>

    <Units />

    <EveryAction />

  </Layout>
)

export default injectIntl(IndexPage)

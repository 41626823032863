import React, { Component } from "react"
import { navigate } from "gatsby"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import styled from "styled-components"

const Form = styled.form`
    width: 100%;
    margin-bottom: 0;
    font-family: "Open Sans", sans-serif;
    label {
        display: block;
        font-size: 1.4rem;
        font-weight: 600;
    }
`

const FormContain = styled.div`
    display: flex;
    padding: 0 20px;
    margin: 0 auto;
    max-width: 700px;  
`

const Input = styled.input`
    width: 60%;
    border: none;
    border-radius: 5px 0px 0px 5px; 
    padding: .5rem 1rem; 
    font-size: 1rem;
`

const Button = styled.button`
    width: 40%;
    padding: .5rem;
    font-size: 1rem;
    font-weight: 700;
    border: none;
    border-radius: 0px 5px 5px 0px; 
    background-color: #000000;
    color: #ffffff;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:hover {
        background-color:#222222;
        color: #eae56a;
        transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    }
    @media (max-width: 1100px) {
        font-size: 14px;
    }
`

class SubscribeForm extends Component {
  constructor(props) {
    super(props)
    this.ContactForm = React.createRef()
    this.state = {}
  }
  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = this.ContactForm.current
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(response => {
        const { intl } = this.props
        const email = encodeURIComponent(this.state.email)
        navigate(`${intl.locale}/success?email=${email}`)
      })
      .catch(error => {
        console.log("====================================")
        console.log(`error in submiting the form data:${error}`)
        console.log("====================================")
      })
  }
  render() {
    const { intl } = this.props

    return (
      <Form
        name="latinextcontacts"
        method="post"
        action={`/${intl.locale}/success`}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        ref={this.ContactForm}
      >
        <input type="hidden" name="form-name" value="latinextcontacts" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </p>

        <FormContain>
          <Input type="email" name="email" placeholder={intl.formatMessage({ id: 'subscribeform.placeholder'})} onChange={this.handleChange} required/>
          <Button type="submit"><FormattedMessage id="subscribeform.submit"/></Button>
        </FormContain>
      </Form>
    )
  }
}
export default injectIntl(SubscribeForm)
import React from "react"
import styled from "styled-components"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import { FaWhatsapp } from "react-icons/fa"

const Link = styled.a`
    font-size: 2rem;
    line-height: 1.7rem; 
    color: #ffffff;
    text-decoration: none;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: center;
    @media (max-width: 680px) {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

`

const Icon = styled.span`
    display: block;
    margin-top: .2rem;
    margin-right: .5rem;
    font-size: 1.7rem;
`

const LinkText = styled.span`
    display: block;
    &:hover {
        text-decoration: underline;
    }
`

const WhatsApp = ({ intl }) => {
    const verb = intl.formatMessage({ id: "whatsappverb" })
    return (
    <div>
        <Link href={`https://wa.me/17732804354?text=${verb}`}>
            <Icon>
                <FaWhatsapp />
            </Icon>
            <LinkText>
                <FormattedMessage id="whatsapplink"/>
            </LinkText>
        </Link>
    </div>
    )
}

export default injectIntl(WhatsApp)
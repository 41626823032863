import React from "react"
import Helmet from "react-helmet"
import { injectIntl } from "gatsby-plugin-intl"

function EveryAction({ intl }) {
  const isSSR = typeof window === "undefined"
  if (!isSSR) {
    return (<>
        <Helmet>
            <script src="https://d1aqhv4sn5kxtx.cloudfront.net/actiontag/at.js" crossOrigin={`anonymous`} />
        </Helmet>
        <div
            className="ngp-form"
            data-form-url={intl.formatMessage({ id: 'donateformurl' })}
            data-fastaction-endpoint="https://fastaction.ngpvan.com"
            data-inline-errors="true"
            data-fastaction-nologin="true"
            data-databag-endpoint="https://profile.ngpvan.com"
            data-databag="everybody"
        />
    </>)
  } else { 
    return null
  }
}

export default injectIntl(EveryAction)
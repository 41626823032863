import React from "react"
import styled from "styled-components"
import { IntlContextConsumer } from "gatsby-plugin-intl"

const languageName = {
  en: "English",
  es: "Español",
}

const Link = styled.a`
    background: none;
    border: none;
    color: #ffffff;
    text-decoration: none;
    padding: 0 .3rem;
    &.active {
        border-bottom: 1px solid #ffffff;
    }
`

const Language = () => {
  return (<div>
    <IntlContextConsumer>
    {({ languages, language: currentLocale }) =>
        languages.map(language => (
        <Link
            key={language}
            href={`/${language}`}
            className={currentLocale === language ? `active` : null}
        >
            {languageName[language]}
        </Link>
        ))
    }
    </IntlContextConsumer>
  </div>)
}

export default Language
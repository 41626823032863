import React from "react"
import styled from 'styled-components'
import { FormattedMessage } from 'gatsby-plugin-intl'

const Unit = styled.section`
    padding: 5rem 1rem 3rem 1rem;
    width: 100%;

    @media (max-width: 680px) {
        padding: 2rem 1rem;
    }
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    margin: 0 auto 2rem auto;
    text-align: center;
    width: 90%;

    border-bottom: 10px solid #eae56a;

    @media (max-width: 680px) {
        grid-template-columns: 1fr;
        padding: 2rem 0;

    }
`

const Sub = styled.h2`
    font-size: 1.5rem;
    font-family: 'Bebas Neue', sans-serif;
`

const Promo = styled.p`
    font-size: .9rem;
    font-family: 'Open Sans', sans-serif;
`

const Units = () => {
    return (
        <Grid>
            <Unit>
                <Sub><FormattedMessage id="units.unit1.hed"/></Sub>
                <Promo><FormattedMessage id="units.unit1.copy"/></Promo>
            </Unit>
            <Unit>
                <Sub><FormattedMessage id="units.unit2.hed"/></Sub>
                <Promo><FormattedMessage id="units.unit2.copy"/></Promo>
            </Unit>
            <Unit>
                <Sub><FormattedMessage id="units.unit3.hed"/></Sub>
                <Promo><FormattedMessage id="units.unit3.copy"/></Promo>
            </Unit>
        </Grid>
    )
}

export default Units